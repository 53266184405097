<template>
	<div v-if="currentUser" class="container">
		<div class="row">
			<div class="col">
				<h1>Profile</h1>
				<div class="edit-form">
					<Form @submit="updateUser" :validation-schema="schema">
						<div class="form-floating mb-3">
							<Field type="text" class="form-control" name="first_name" id="first_name" v-model="currentUser.first_name" />
							<label for="first_name">First name</label>
							<ErrorMessage name="first_name" class="error-feedback d-block alert alert-warning" />
						</div>
						<div class="form-floating mb-3">
							<Field type="text" class="form-control" name="last_name" id="last_name" v-model="currentUser.last_name" />
							<label for="last_name">Last name</label>
							<ErrorMessage name="last_name" class="error-feedback d-block alert alert-warning" />
						</div>
						<div class="form-floating mb-3">
							<Field type="text" class="form-control" name="email" id="email" v-model="currentUser.email" />
							<label for="email">Email</label>
							<ErrorMessage name="email" class="error-feedback d-block alert alert-warning" />
						</div>
						<div class="form-floating mb-3">
							<Field type="text" class="form-control" name="phone" id="phone" v-model="currentUser.phone" />
							<label for="phone">Phone</label>
							<ErrorMessage name="phone" class="error-feedback d-block alert alert-warning" />
						</div>
						<div class="form-floating mb-3">
							<Field type="text" class="form-control" name="mobile" id="mobile" v-model="currentUser.mobile" />
							<label for="mobile">Mobile</label>
							<ErrorMessage name="mobile" class="error-feedback d-block alert alert-warning" />
						</div>
						<div class="form-floating mb-3">
							<Field type="text" class="form-control" name="web" id="web" v-model="currentUser.web" />
							<label for="web">Web</label>
							<ErrorMessage name="web" class="error-feedback d-block alert alert-warning" />
						</div>

						<div class="row">
							<div class="col mb-3">
								<label for="hexcolor">Hexcolor</label>
								<Field name="hexcolor" type="color" id="hexcolor" class="form-control form-control-color" v-model="currentUser.hexcolor" />
								<ErrorMessage name="hexcolor" class="error-feedback d-block alert alert-warning" />
							</div>

							<div class="col mb-3">
								<label for="icon">Icon</label>
								<Field v-slot="{value}" name="icon" as="select" v-model="currentUser.icon" class="form-select" aria-label="Icon">
									<option value="" disabled>Select a icon</option>
									<option v-for="(icon, index) in icons" :key="icon" :value="index" :selected="value && value.includes(index)">{{ icon }}</option>
								</Field>
							</div>
						</div>

						<div class="mb-3">
							<button class="btn btn-primary me-2" :validation-schema="schema" :disabled="updateProgess">
								<span v-show="updateProgess" class="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true"></span>
								<span>Update</span>
							</button>
						</div>
					</Form>
				</div>
			</div>
		</div>
	</div>

	<div class="d-block mt-5 pt-5"></div>
</template>

<script>
import UserService from '../services/user.service';
import {Form, Field, ErrorMessage} from 'vee-validate';
import * as yup from 'yup';

export default {
	name: 'AppProfile',
	components: {
		Form,
		Field,
		ErrorMessage,
	},
	data() {
		const schema = yup.object().shape({
			first_name: yup.string().required('Is required!'),
			last_name: yup.string().required('Is required!'),
			email: yup.string().required('Is required!'),
		});

		return {
			currentUser: null,
			currentUserId: null,
			updateProgess: false,
			schema,
			icons: null,
		};
	},
	methods: {
		retrieveUser(id) {
			UserService.get(id)
				.then((response) => {
					// Check if success, if not show error message
					if (!response.data.error) {
						this.currentUser = response.data.user;
					} else {
						this.currentUser = null;
						this.$toast.warning(response.data.error.message);
					}
				})
				.catch((e) => {
					this.catchError(e);
				});
		},
		updateUser() {
			this.updateProgess = true;

			UserService.update(this.currentUser.id, this.currentUser)
				.then((response) => {
					// Check if success, if not show error message
					if (!response.data.error) {

						// Update localStorage
						this.$store.state.auth.user.icon = this.currentUser.icon;
						localStorage.setItem('user', JSON.stringify(this.$store.state.auth.user));

						this.$toast.success(response.data.success.message);
					} else {
						this.$toast.info(response.data.error.message);
					}
					this.updateProgess = false;
				})
				.catch((e) => {
					this.catchError(e);
				});
		},
		catchError(e) {
			// Check if not authorized, if not show error message
			if (e.response.status === 401) {
				this.$store.dispatch('auth/logout');
				this.$router.push('/login');
			} else {
				this.updateProgess = false;
				console.log(e);
			}
		},
	},
	created() {
		this.icons = this.$store.state.icons.options;
		this.currentUserId = this.$store.state.auth.user.id;
		this.retrieveUser(this.currentUserId);
	},
	mounted() {
	},
};
</script>
