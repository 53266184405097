import axios from 'axios';
import authHeader from './auth.header';

const API_URL = process.env.VUE_APP_API_URL;

class UserService {
	getStatus() {
		return axios.get(API_URL + 'status', {headers: authHeader()});
	}

	getAll() {
		return axios.get(API_URL + 'users', {headers: authHeader()});
	}

	update(id, data) {
		return axios.put(API_URL + `user/${id}`, data, {headers: authHeader()});
	}

	get(id) {
		return axios.get(API_URL + `user/${id}`, {headers: authHeader()});
	}

	delete(id) {
		return axios.delete(API_URL + `user/${id}`, {headers: authHeader()});
	}
}

export default new UserService();
